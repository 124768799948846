import { useEffect, useRef, useState } from "react";
import { AnnotationLayerFactory } from "../annotations/annotation-layer-factory";
import {
  EventBus,
  LinkTarget,
  NullL10n,
  PDFFindController,
  PDFPageView
} from "pdfjs-dist/legacy/web/pdf_viewer";
import { PDFDocumentProxy, getDocument } from "pdfjs-dist/legacy/build/pdf";
import { CustomPDFViewer } from "./custom-pdf-viewer";
import { CustomPDFLinkService } from "./custom-pdf-link-service";

const ScaleValue = "auto";

// we experience "flooding" of api when rendering large documents
const SUITABLE_RANGE_CHUNK_SIZE = 8388608;

export function useSetupPdfViewer(url: string, ticketId?: string) {
  // reference to div so we can pass to PdfJs PdfViewer component
  const pdfViewContainerRef = useRef<HTMLDivElement>(null);

  const pdfViewerElementRef = useRef<HTMLDivElement>(null);

  // hold a reference PdfViewer instance so we can interact with it
  const [pdfViewer, setPdfViewer] = useState<CustomPDFViewer>();

  // hold a reference to AnnotationLayerFactory instance so we can interact with it
  const [annotationLayerFactory, setAnnotationLayerFactory] =
    useState<AnnotationLayerFactory>();

  useEffect(() => {
    // Pdfjs supports loading documents in chunks out of the box. However API (under the provided url endpoint) needs to support range requests.
    // Our primary variant endpoint is supporting chunks/range requests, so it should be working fine.
    // Also PDF documents should be web optimized (linearized) for pdfjs to support loading in chunks but is seams
    // that is not necessary. If it is not linearized, the difference is pdfjs seems to be requesting for chunks in sequence
    // instead of in parallel (which happens with linearized one)
    // See https://github.com/mozilla/pdf.js/issues/9537
    (async () => {
      const pdfLoadingTask = getDocument(getPdfSource(url, ticketId));

      const pdf = await pdfLoadingTask.promise;

      if (
        !pdfViewContainerRef.current?.firstChild ||
        !pdfViewerElementRef.current
      ) {
        // can we end up in situation where div is not rendered before pdf is loaded?? Then there is bug here
        return;
      }

      const { pdfViewer, annotationLayerFactory } = renderPdf(
        pdf,
        pdfViewContainerRef.current,
        pdfViewerElementRef.current
      );

      // save instances pdfjs components instances to state for later interactions
      setPdfViewer(pdfViewer);
      setAnnotationLayerFactory(annotationLayerFactory);
    })();
  }, [url, ticketId]);

  useEffect(() => {
    const handleResize = () => {
      if (pdfViewer) pdfViewer.currentScaleValue = ScaleValue;
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pdfViewer]);

  return {
    pdfViewer,
    pdfViewContainerRef,
    pdfViewerElementRef,
    annotationLayerFactory
  };
}

/**
 * This creates a new instance of PDFViewer and its related components
 * @param pdf
 * @param viewerContainerElement
 * @returns
 */
function renderPdf(
  pdf: PDFDocumentProxy,
  container: HTMLDivElement,
  viewer: HTMLDivElement
) {
  const annotationLayerFactory = new AnnotationLayerFactory();

  const eventBus = new EventBus();

  eventBus.on("pagesinit", () => {
    // scaling pdf initially to pdf viewport width
    // taken from https://github.com/mozilla/pdf.js/blob/44cde3ccca05162b5d1c4908000f6907ba21fb9a/examples/components/simpleviewer.mjs#L76
    pdfViewer.currentScaleValue = ScaleValue;
    // When scaling the library also scrolls current page, and this scrolling doesn't take our other content into content account
    // probably because we have hacked the source code around scroll so that its is the window that works as scroll container instead of the container div
    // this window scrollTo mitigates this problem when initialising the pdf
    // note that scrolling to page happens elsewhere and
    window.scrollTo(0, 0);
  });

  eventBus.on(
    "pagerendered",
    (e: { pageNumber: number; source: PDFPageView }) => {
      // Only scale page if ScaleValue has changed.
      // Setting ScaleValue on every page rendered event, would trigger a re-render, and sometimes caused a bug:
      // "text_highlighter.js:114  Uncaught (in promise) Error: TextHighlighter is already enabled."
      if (pdfViewer.currentScaleValue !== ScaleValue) {
        pdfViewer.currentScaleValue = ScaleValue;
      }
    }
  );

  const linkService = new CustomPDFLinkService({
    eventBus,
    // open external links in new tab
    externalLinkTarget: LinkTarget.BLANK,
    // This is added to prevent zooming when clicking on internal ('FitR') links. Bug 219124
    ignoreDestinationZoom: true
  });

  const findController = new PDFFindController({
    linkService,
    eventBus
  });

  const options = {
    container,
    viewer,
    eventBus,
    linkService,
    findController,
    // We set useOnlyCssZoom to default value "false", since this results in "sharp" rendering of the pdf
    // Note that historically we have rendered pdfs with this set to true, because we had problems with infinite rescaling when false
    // this has been fixed here in new design, however, we still need to be aware of this setting when implementing markup and annotations in new design
    // since we might have to figure out a way to do scaling of annotations in different way than old design when this setting is disabled
    useOnlyCssZoom: false,
    // removePageBorders let's scrolling to pages more precise as we don't have to adjust for border-sizes when offsetting
    removePageBorders: true,
    renderer: "canvas",
    l10n: NullL10n
  };
  // Notice there are memory leak with PdfViewer since it hooks up on events in constructor and other places
  // but these never get removed, so instances keep existing in memory handling events even though they are orphaned
  // hopefully will be fixed when upgrading pdfjs-dist

  console.debug(
    "Creating new PDFViewer instance (it is expected that only one instance will be created, for one displaying of pdf document)"
  );
  const pdfViewer = new CustomPDFViewer(options, annotationLayerFactory);
  pdfViewer.setDocument(pdf);
  pdfViewer.currentScaleValue = ScaleValue;

  linkService.setDocument(pdf, null);
  linkService.setViewer(pdfViewer);
  return { pdfViewer, annotationLayerFactory };
}
/**
 * saving to state pdf source object because its
 */
export function getPdfSource(url: string, ticketId?: string) {
  // Add useEffect to update state if ticket or url changes runtime
  return {
    url,
    rangeChunkSize: SUITABLE_RANGE_CHUNK_SIZE,
    httpHeaders: ticketId ? { ticket: ticketId } : undefined
  };
}
