import { LegacyNavBarHeightInPixels } from "../../../../../legacy-app-support/LegacyGlobalCssOverrides";
import { DocumentToolBarHeightInPixels } from "../../../shared/DocumentToolbar";
import { PDFLinkService } from "pdfjs-dist/legacy/web/pdf_viewer";

/**
 * We have subclassed PDFLinkService to override some functionality.
 * goToDestination is called when an internal link is clicked in the PDF.
 * We override this method to adjust the scroll position to account for the fixed navbar and document toolbar.
 */
export class CustomPDFLinkService extends PDFLinkService {
  constructor(options: any) {
    super(options);
  }
  public override async goToDestination(dest: string | any[]) {
    await super.goToDestination(dest);

    // Adjust scroll position to account for the fixed navbar and document toolbar
    window.scrollBy(
      0,
      -(LegacyNavBarHeightInPixels + DocumentToolBarHeightInPixels)
    );
  }
}
